@use 'mdsass' as util;

@mixin text-field-filled {
	@include util.block;

	position: relative;
	flex-flow: row;

	> span {
		position: absolute;
		inset-block-start: 8px;
		inset-inline-start: 16px;
		color: var(--md-sys-color-on-surface-variant);
		//@include apply-typescale('md-sys-typescale-body-small');
	}

	> :not(span) {
		&:not(textarea) {
			height: 56px;
		}

		border: 0px;
		outline: none;
		resize: none;

		flex: 1 1 auto;
		padding-inline: 16px;
		padding-block: 8px;
		padding-block-start: 24px;
		@include util.apply-typescale('md-sys-typescale-body-medium');
		background-color: var(--md-sys-color-surface-container-highest);
		color: var(--md-sys-color-on-surface);
		border-block-end: 1px solid var(--md-sys-color-on-surface-variant);
	}

	&:focus-within {
		> span {
			color: var(--md-sys-color-primary);
		}
		> :not(span) {
			padding-block-end: 7px;
			border-block-end: 2px solid var(--md-sys-color-primary);
		}
	}
}
